import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
const axios = require("axios").default;

function App() {
	// const [electricityDay, setElectricityDay] = useState(0.0);
	// const [electricityNight, setElectricityNight] = useState(0.0);
	// const [gas, setGas] = useState(0.0);

	// useEffect(() => {
	// 	fetchElectricityData();
	// 	fetchElectricityDataII();
	// 	fetchGasData();
	// }, []);

	// async function fetchElectricityDataII() {
	// 	console.log("hey");
	// 	let electricityResponse = await axios.get(
	// 		"https://web.scraper.workers.dev/?url=callmepower.be/nl/energie/gids/tarief/prijs-elektriciteit&selector=script"
	// 	);
	// 	electricityResponse = electricityResponse.data.result.script[2];
	// 	console.log(electricityResponse);
	// 	const electricityGraphString =
	// 		electricityResponse.match(/labels (.*?) options/);
	// 	console.log(electricityGraphString);
	// }

	// async function fetchElectricityData() {
	// 	const electricityResponse = await axios.get("https://tinyurl.com/4fyeypnh");
	// 	const electricityResponseRaw = electricityResponse.data.result.tr.slice(
	// 		1,
	// 		electricityResponse.length
	// 	);
	// 	electricityResponseRaw.pop();

	// 	let count = 0;
	// 	let dayRunner = 0.0;
	// 	let nightRunner = 0.0;
	// 	electricityResponseRaw.forEach((e) => {
	// 		count = count + 1;
	// 		const parsed = e.split("\n");
	// 		dayRunner = dayRunner + parseFloat(parsed[1]);
	// 		nightRunner = nightRunner + parseFloat(parsed[2]);
	// 	});

	// 	setElectricityDay(dayRunner / count);
	// 	setElectricityNight(nightRunner / count);
	// }

	// async function fetchGasData() {
	// 	const gasResponse = await axios.get("https://tinyurl.com/4t43sxwp");
	// 	const gasResponseRaw = gasResponse.data.result.tr.slice(
	// 		1,
	// 		gasResponse.length
	// 	);
	// 	gasResponseRaw.pop();

	// 	let count = 0;
	// 	let gasRunner = 0.0;
	// 	gasResponseRaw.forEach((e) => {
	// 		count = count + 1;
	// 		const parsed = e.split("\n");
	// 		gasRunner = gasRunner + parseFloat(parsed[1]);
	// 	});

	// 	setGas(gasRunner / count);
	// }

	// OCTOBER //
	const electricityBefore = 1051;
	const electricityNow = 3256;
	const gasBefore = 1621;
	const gasNow = 8103;

	return (
		<div className="App">
			<header className="App-header"></header>
			<body>
				<div className="container">
					<h1>
						Hoeveel duurder is gas en elektriciteit vandaag dan 1 jaar geleden?
					</h1>
					<div className="stats">
						<div className="stat">
							<h2>Gas</h2>
							<p>{(gasNow / gasBefore).toFixed(2)} X</p>
						</div>
						<div className="stat">
							<h2>Elektriciteit</h2>
							<p>{(electricityNow / electricityBefore).toFixed(2)} X</p>
						</div>
					</div>
				</div>
				<div className="footer">
					<span>Bron: VREG, 2022</span>
				</div>
			</body>
		</div>
	);
}

export default App;
